<style scoped>
.detect-info >>> .el-card__header {
  border: none;
}
.detect-info .el-card__header .icon-fanhui-:before {
  margin-right: 10px;
}
.detect-info .back-btn {
  color: #333;
  margin-left: 65px;
  position: absolute;
  left: 0;
  top: 50px;
}
.card-title {
  font-size: 30px;
  /* line-height: 80px; */
  color: #333333;
  margin-top: 60px;
}
.card-content {
  /* width: 1116px; */
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-around;
}
.detect-info .content {
  padding: 0 65px;
  box-sizing: border-box;
}
.detect-info .content .search-box {
  display: flex;
  justify-content: space-between;
}
.detect-info .content .content-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0;
}
.detect-info .content .content-box .content-title {
  font-size: 24px;
  color: #333333;
  font-weight: normal;
  margin-bottom: 30px;
}
.detect-info .content .content-box .item-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* align-items: center; */
}
.detect-info .content .content-box .item-box .item {
  margin: 0 20px 0;
  cursor: pointer;
}
</style>
<template>
  <div class="detect-info" id="container">
    <el-card>
      <div slot="header">
        <el-button
          class="back-btn iconfont icon-fanhui-"
          type="text"
          @click="backHome"
        >
          返回
        </el-button>
        <p class="card-title" v-if="patient_project_name == 'HID'">
          人类传染性疾病（HID）
        </p>
        <p class="card-title" v-else-if="patient_project_name == 'PG'">
          药物基因组（PG）
        </p>
        <p class="card-title" v-else>动物感染疾病（AID）</p>
      </div>
      <div class="content">
        <div class="search-box">
          <date-picker
            @dateChange="dateChange"
            @typeChange="typeChange"
            :type="time_type"
            :dateValue="from_time"
          ></date-picker>
        </div>
        <el-empty
          v-if="isShow"
          :image="emptyImg"
          :image-size="400"
          style="margin-bottom: 20px"
        >
          <template slot="description">
            <span style="color: #999"> 没有查询到相应内容 </span>
          </template>
        </el-empty>
        <div class="content-box" v-else>
          <el-row :gutter="30" class="item-box">
            <el-col
              :span="12"
              class="item"
              v-for="item in list"
              :key="item.id"
              @click.native="toDetectLine(item.patient_project_item)"
            >
              <info-item :list="item"></info-item>
              <div class="btn-box">
                <el-button
                  icon="el-icon-download"
                  type="primary"
                  plain
                  @click.stop="download(item.patient_project_item)"
                  >下载结果</el-button
                >
              </div>
            </el-col>
          </el-row>
          <div class="card-bottom">
            <el-pagination
              class="pagination"
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="2"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import InfoItem from "@/components/InfoItem/InfoItem.vue";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
import { projectItemTotal, operationStatExport } from "@/api/base";
export default {
  name: "detectInfo",
  data() {
    return {
      emptyImg: require("@/assets/images/empty.png"),
      time_type: null,
      from_time: null,
      to_time: null,
      patient_project_name: null,
      list: null, //分页后展示数据
      total: null,
      dataList: [], //原始数据
      current: null,
      isShow: false,
    };
  },
  components: {
    DatePicker,
    InfoItem,
  },
  created() {
    //初始化数据
    this.patient_project_name = this.$route.query.title;
    this.time_type = this.$route.query.time_type;
    this.from_time = this.$route.query.from_time;
    this.to_time = this.$route.query.to_time;
    this.getList();
  },
  methods: {
    //获取列表信息
    async getList(page = 1) {
        this.total = 0
        this.list = []
      this.current = page;
      let { time_type, from_time, to_time, patient_project_name, current } =
        this;
      let res = await projectItemTotal({
        time_type,
        from_time,
        to_time,
        patient_project_name,
        current,
      });
      if (res.code ==1 &&res.data?.records[0]?.item) {
          //有值
        this.isShow = false;
        this.dataList = res.data.records[0].item;
        this.total = this.dataList.length;
        this.list = this.pagination(this.dataList, page);
      } else {
        this.isShow = true;
        this.list = []
      }
    },
    //前端分页  list是需要分页的数据  n是传入第几页 从第一页开始  根据传入的页码 返回需要展示的数组
    handleCurrentChange(page) {
      let data = this.dataList;
      this.list = this.pagination(data, page);
    },
    //2为每页的size 也可以改为动态传参
    pagination(list, n) {
      let start = 2 * (n - 1);
      let end = 2 * (n - 1) + 2;
      return list.slice(start, end);
    },
    //返回
    backHome() {
      this.$router.back();
    },
    //跳转detect统计页面
    toDetectLine(data) {
      this.$router.push({
        path: "/detectLine",
        query: {
          time_type: this.time_type,
          from_time: this.from_time,
          to_time: this.to_time,
          patient_project_name: this.patient_project_name,
          patient_project_item: data,
        },
      });
    },
    //时间类型选择
    typeChange(e) {
      this.time_type = e;
    },
    //时间选择器返回值 e.dateValue  e.selectValue
    dateChange(e) {
      if (!this.time_type) {
        this.time_type = "3";
      }
      this.from_time = e.from_time;
      this.to_time = e.to_time;
      this.getList();
    },
    //下载
    async download(data) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let { patient_project_name, from_time, to_time, time_type } = this;
      const res = await operationStatExport({
        patient_project_item: data,
        patient_project_name,
        from_time,
        to_time,
        time_type,
        flag: 1,
      });
      if (res) {
        const blob = new Blob([res]);
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `${data}.xlsx`);
        link.click();
        link = null;
      }
      loading.close();
    },
  },
};
</script>

<style>
</style>