<style scoped>
.info-item {
  width: 100%;
  height: 314px;
  background-color: #ffffff;
  border-radius: 5px;
  /* border: solid 1px #3798ee; */
  padding: 20px 25px 0px;
}
.info-item .item-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
.info-item .item-top .item-title {
  font-size: 20px;
  color: #333333;
}
.info-item .item-top i {
  font-size: 24px;
}
.info-item .cicle-box {
  display: flex;
  justify-content: space-around;
}
.info-item .cicle-box .cicle-item {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
}
.info-item .cicle-box .cicle-item .num-box {
  display: flex;
  align-items: center;
  text-align: left;
}
.info-item .cicle-box .cicle-item .num-box .cicle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
}
.info-item .cicle-box .cicle-item .num-box span {
  line-height: 26px;
  color: #626262;
  margin-right: 10px;
}
.info-item .cicle-box .cicle-item .num-box .num {
  font-size: 20px;
  color: #3798ee;
}
</style>
<template>
  <div class="info-item">
    <div class="item-top">
      <div class="item-title">{{ itemTitle }}</div>
      <i class="iconfont icon-xiangyoujiantou"></i>
    </div>
    <div class="cicle-box">
      <div class="cicle-item">
        <van-circle
          :value="value1"
          :text="flagText1"
          stroke-width="80"
          :color="blueColor"
          layer-color="#ccc"
          size="140"
          style="margin-bottom: 20px"
        ></van-circle>
        <div class="num-box">
          <i class="cicle" style="background-color: #067cf2"></i>
          <span>检测成功:</span>
          <span class="num">{{ flag_1 }}</span>
        </div>
        <div class="num-box">
          <i class="cicle" style="background-color: #ccc"></i>
          <span>检测失败:</span>
          <span class="num">{{ flag_0 }}</span>
        </div>
      </div>
      <div class="cicle-item">
        <van-circle
          size="140"
          :value="value2"
          :text="flagText2"
          stroke-width="80"
          :color="yellowColor"
          layer-color="#ccc"
          style="margin-bottom: 20px"
        ></van-circle>
        <div class="num-box">
          <i class="cicle" style="background-color: #067cf2"></i>
          <span>QC正常:</span>
          <span class="num">{{ qc_1 }}</span>
        </div>
        <div class="num-box">
          <i class="cicle" style="background-color: #ccc"></i>
          <span>QC异常:</span>
          <span class="num">{{ qc_0 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoItem",
  props: {
    list: Object,
  },
  data() {
    return {
      blueColor: {
        "0%": "#0379f1",
        "100%": "#2dbcfe",
      },
      yellowColor: {
        "0%": "#ff9947",
        "100%": "#ffc775",
      },
      value1: "0",
      flagText1: "成功率",
      value2: "0",
      flagText2: "正常率",
      itemTitle: "新冠病毒",
      flag_0: "0",
      flag_1: "0",
      qc_0: "0",
      qc_1: "0",
    };
  },
  created() {
    this.init();
  },
  watch: {
    list() {
      this.init();
    },
  },
  components: {},
  methods: {
    //初始化数据
    init() {
      this.itemTitle = this.list.patient_project_item;
      this.flag_0 = this.list.flag.flag_0;
      this.flag_1 = this.list.flag.flag_1;
      this.qc_0 = this.list.qc.qc_0;
      this.qc_1 = this.list.qc.qc_1;
      if (this.flag_0 == 0 && this.flag_1 == 0) {
        this.value1 = 0;
      } else {
        this.value1 = parseInt(
          ((this.list.flag.flag_1 || 0) /
            ((this.list.flag.flag_1 || 0) + (this.list.flag.flag_0 || 0))) *
            100
        );
      }
      this.flagText1 = this.value1 + "%\n成功率";
      if( this.qc_0 == 0 && this.qc_1 ==0){
          this.value2 = 0
      }else{
    this.value2 = parseInt(
        ((this.list.qc.qc_1 || 0) /
          ((this.list.qc.qc_1 || 0) + (this.list.qc.qc_0 || 0))) *
          100
      );
      }
  
      this.flagText2 = this.value2 + "%\n正常率";
    },
  },
  computed: {
    // text() {
    //   return this.currentRate.toFixed(0) + "%";
    // },
  },
};
</script>
